@font-face {
     font-family: WorkSans;
     src: url('./assets/WorkSans-VariableFont_wght.ttf');
}

.fit-h{
     height: fit-content;
 }
 .fit-w{
     width: fit-content;
 }

:root{
     --ancho_sidebar: 200px;
     --max-width-mobile: 600px;
}

body{
     margin:0;
     font-family: WorkSans;
     background-color: #f3f5f7;
}

input,select{
     margin-bottom: 16px;
     /*margin-top: 12px;*/
     padding-left: 12px!important;
     padding-right: 16px!important;
     font-size: 14px;
     display: block;
}

.no-disponible{
     width: calc(95vw - var(--ancho_sidebar));
     height: 100vh;
     position: fixed;
     background-color: #000000d0;
     z-index: 9999;
     margin-left: -5vw;
     display: flex;
     justify-content: center;
     align-items: center;
}

.no-disponible > p{
     color: white;
     font-size: large;
     border-bottom: 2px solid #01b6f8;
}

/*Etiquetas de los inputs*/
label{
     font-size: 11px;
}


.titulo-componente{
     margin-top: 40px;
     font-weight: bold;
}

.cabecera-componente{
     width: 100%;
     display: flex;
     flex-flow: nowrap row;
     align-items: center;
     margin-top: 25px;
}

.cabecera-componente > .titulo-componente{
     margin:0px;
}

.boton-atras{
     height: 2em;
     aspect-ratio: 1;
     margin-right: 12px;
}

.boton-atras > img {
     width: 100%;
}


.circulo-notificacion{
     background-color: #fc00ff;
     border-radius: 50%;
     height: 18px;
     display: flex;
     width: 18px;
     justify-content: center;
     align-items: center;
     font-size: 12px;
     color: white;
}


.alerta_formulario{
     background-color: #e58181;
     border: 1px solid #f31d1d;
     color:#7f1b1b;
     width: 100%;
     padding: 5px;
}

.aviso_formulario{
     background-color: #74cb74;
     border: 1px solid #033603;
     color: #033603;
     padding: 5px;
}


.check-formulario{
     padding: 0px!important;
     aspect-ratio: 1;
}



/*=========PAGINACIONES=============*/
.div-paginacion{
     display: flex;
     justify-content: space-between; /*NO ESTABA*/
}

.div-paginacion > .atras,
.div-paginacion > .siguiente
{
     display: flex;
     width: 40%;
}

.div-paginacion > .atras{
     justify-content: left;
}

.div-paginacion > .siguiente{
     justify-content: right;
}

.div-paginacion > .paginas{
     justify-content: center;
}

/*============TABLAS===================*/
table{
     border-radius: 6px;
     box-shadow: 0 3px 6px 0 rgba(157, 206, 255, 0.16);
     border: solid 1px #e6e6e6;
     background-color: #fff;
}

td, th{
     padding-top: 12px;
     padding-bottom: 12px;
     padding-right: 24px;
     padding-left: 24px;
     text-align: center;
     white-space: nowrap;
}

tr{
     border-bottom: solid 1px #e6e6e6;
}

.label_tabla{
     text-transform: uppercase;
     border-radius: 18px;
     padding : 6px;
     font-size: smaller;
     border: 1px solid lightgray;
}

.label_tabla.rojo{
     border: solid 1px #d14747;
     background-color: #f5d6d6;
}

.label_tabla.verde{
     font-size: smaller;
     border: solid 1px #4db299;
     background-color: #dbf0eb;
}

.label_tabla.amarillo{
     border: solid 1px #e8bb7d;
     background-color: #fff0bc;
}

td > .descripcion {
     font-size: 10px;
     line-height: 1.1;
     color: #c7c4c4;
}


/*=======================.botones====================*/

.boton{
     border: none;
     color: white;
     border-radius: 6px;
     -webkit-backdrop-filter: blur(30px);
     backdrop-filter: blur(30px);
     box-shadow: 0 10px 12px 0 rgba(70, 142, 243, 0.41);
     background-image: linear-gradient(to right, #7f6def 3%, #01b6f8);
     padding-left: 16px;
     padding-right: 16px;
     margin:0.5em;
     cursor: pointer;
}

.boton.large{
     font-size: 16px;
     padding-top: 14px;
     padding-bottom: 14px;
}

.boton.medium{
     font-size: 14px;
     padding-top: 10px;
     padding-bottom: 10px;
}

.boton.small{
     font-size: 12px;
     padding-top: 5px;
     padding-bottom: 5px;
}

.boton.icono{
     padding:5px;
}

.boton.icono > img{
     height: 20px;
}

a.boton.icono{
     padding: 12px;
}

.boton:hover{
     background-color: #01b6f8;
}

.boton.disabled{
     background-color: #c7c4c4!important;
     background-image: none;
     color:#727272 !important;
     border-color: #727272!important;
     box-shadow: none;
     cursor: no-drop;
}


.boton.secondary{
     border: solid 1px #01b6f8;
     background-color: #fff;
     color: #01b6f8;
     background-image: none;
     box-shadow: none;
}

.boton.secondary.icono:hover{
     color: #fff;
     background-color: #ccf1f7;
}

.boton.secondary:hover{
     background-color: #01b6f8;
     color: #fff;
}



.boton.danger{
     background-color: #d14747;
     color: #fff;
     background-image: none;
     box-shadow: none;
}

.boton.danger:hover{
     background-color: #b42525;
}

.boton.danger.secondary{
     background-color: #fff;
     color: #d14747;
     border: solid 1px #d14747;
}

.boton.danger.secondary:hover{
     background-color: #e6e6e6;
     color: #d14747;
}

.boton.aceptar{
     background-color: #026502;
     color: #fff;
     background-image: none;
     box-shadow: none;
}

.boton.aceptar:hover{
     background-color: #026502;
}

.boton.aceptar.secondary{
     background-color: #fff;
     color: #026502;
     border: solid 1px #026502;
}

.boton.aceptar.secondary:hover{
     background-color: #e6e6e6;
     color: #026502;
}

/*====================TARJETAS==================*/
.etiqueta{
     padding: 5px 15px 5px 15px;
     border-radius: 18px;
     border: solid 1px #727272;
     background-color: #e6e6e6;
}

.etiqueta.verde{
     border-radius: 18px;
     border: solid 1px #4db299;
     background-color: #dbf0eb;
}

.etiqueta.amarilla{
     border-radius: 18px;
     border: solid 1px #e8bb7d;
     background-color: #fff0bc;
}


.tarjeta{
    background-color: transparent!important;
     border-radius: 6px;
     border: solid 1px #e6e6e6;
     width: 100%;
     padding-top: 24px;
}

.tarjeta > .inner-card > .imagen{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 15px;
}

.tarjeta> .inner-card > .imagen > img {
     width: 40%;
     border-radius: 50%;
}

.tarjeta > .inner-card > .titulo{
     font-weight: bold;
}

.tarjeta > .inner-card > .labels{
     padding-top: 16px;
     padding-bottom: 16px;
}

.tarjeta > .inner-card > .contenido{
     margin-bottom: 16px;
}

.tarjeta > .inner-card > .fechas{
     color: #727272;
}

.tarjeta > .footer{
     border-top: solid 1px #e6e6e6;
     margin-top: 24px;
     padding: 16px;
     text-align: center;
     color: #01b6f8;
}
/*===============ICONOS======================*/

.div-icono{
     width: 25px;
     height: 25px;
     padding: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 50%;
}

.div-icono.danger{
     background-color: #d14747;
}


.div-icono > .icono-svg{
     width: 100%;
     aspect-ratio: 1;
}

.icono-svg{
     width: 20px;
     aspect-ratio: 1;
}

.icono-svg > path,
.icono-svg > rect{
     stroke: black;
}

.div-icono.danger > .icono-svg > path,
.div-icono.danger > .icono-svg > rect{
     stroke: white;
}

/*===============CARGANDO======================*/
.sk-circle {
     margin: 100px auto;
     width: 40px;
     height: 40px;
     position: relative;
   }
   .sk-circle .sk-child {
     width: 100%;
     height: 100%;
     position: absolute;
     left: 0;
     top: 0;
   }
   .sk-circle .sk-child:before {
     content: '';
     display: block;
     margin: 0 auto;
     width: 15%;
     height: 15%;
     background-color: #333;
     border-radius: 100%;
     -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
             animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
   }
   .sk-circle .sk-circle2 {
     -webkit-transform: rotate(30deg);
         -ms-transform: rotate(30deg);
             transform: rotate(30deg); }
   .sk-circle .sk-circle3 {
     -webkit-transform: rotate(60deg);
         -ms-transform: rotate(60deg);
             transform: rotate(60deg); }
   .sk-circle .sk-circle4 {
     -webkit-transform: rotate(90deg);
         -ms-transform: rotate(90deg);
             transform: rotate(90deg); }
   .sk-circle .sk-circle5 {
     -webkit-transform: rotate(120deg);
         -ms-transform: rotate(120deg);
             transform: rotate(120deg); }
   .sk-circle .sk-circle6 {
     -webkit-transform: rotate(150deg);
         -ms-transform: rotate(150deg);
             transform: rotate(150deg); }
   .sk-circle .sk-circle7 {
     -webkit-transform: rotate(180deg);
         -ms-transform: rotate(180deg);
             transform: rotate(180deg); }
   .sk-circle .sk-circle8 {
     -webkit-transform: rotate(210deg);
         -ms-transform: rotate(210deg);
             transform: rotate(210deg); }
   .sk-circle .sk-circle9 {
     -webkit-transform: rotate(240deg);
         -ms-transform: rotate(240deg);
             transform: rotate(240deg); }
   .sk-circle .sk-circle10 {
     -webkit-transform: rotate(270deg);
         -ms-transform: rotate(270deg);
             transform: rotate(270deg); }
   .sk-circle .sk-circle11 {
     -webkit-transform: rotate(300deg);
         -ms-transform: rotate(300deg);
             transform: rotate(300deg); }
   .sk-circle .sk-circle12 {
     -webkit-transform: rotate(330deg);
         -ms-transform: rotate(330deg);
             transform: rotate(330deg); }
   .sk-circle .sk-circle2:before {
     -webkit-animation-delay: -1.1s;
             animation-delay: -1.1s; }
   .sk-circle .sk-circle3:before {
     -webkit-animation-delay: -1s;
             animation-delay: -1s; }
   .sk-circle .sk-circle4:before {
     -webkit-animation-delay: -0.9s;
             animation-delay: -0.9s; }
   .sk-circle .sk-circle5:before {
     -webkit-animation-delay: -0.8s;
             animation-delay: -0.8s; }
   .sk-circle .sk-circle6:before {
     -webkit-animation-delay: -0.7s;
             animation-delay: -0.7s; }
   .sk-circle .sk-circle7:before {
     -webkit-animation-delay: -0.6s;
             animation-delay: -0.6s; }
   .sk-circle .sk-circle8:before {
     -webkit-animation-delay: -0.5s;
             animation-delay: -0.5s; }
   .sk-circle .sk-circle9:before {
     -webkit-animation-delay: -0.4s;
             animation-delay: -0.4s; }
   .sk-circle .sk-circle10:before {
     -webkit-animation-delay: -0.3s;
             animation-delay: -0.3s; }
   .sk-circle .sk-circle11:before {
     -webkit-animation-delay: -0.2s;
             animation-delay: -0.2s; }
   .sk-circle .sk-circle12:before {
     -webkit-animation-delay: -0.1s;
             animation-delay: -0.1s; }


/*===============CURSOR CLICLABLE======================*/
.clicable {
     cursor: pointer;
}
.noclicable{
     cursor: auto;
}

/*=============== Circulo info ======================*/
.btn_informacion{
     cursor: pointer;
     height: 1.6rem;
     width: 1.6rem;
     margin-bottom: 1.5rem;
}

.btn_informacion_min{
     cursor: pointer;
     height: 1rem;
     width: 1rem;
     margin-bottom: 1rem;
}


   @-webkit-keyframes sk-circleBounceDelay {
     0%, 80%, 100% {
       -webkit-transform: scale(0);
               transform: scale(0);
     } 40% {
       -webkit-transform: scale(1);
               transform: scale(1);
     }
   }

   @keyframes sk-circleBounceDelay {
     0%, 80%, 100% {
       -webkit-transform: scale(0);
               transform: scale(0);
     } 40% {
       -webkit-transform: scale(1);
               transform: scale(1);
     }
   }

/*--------------MODALES-------------------*/
.modal-header{
  color: black;
}

.boton-cerrar-modal{
  height: 1em;
}

.modal-body {
  display: flex;
  flex-flow: wrap row;
}

.modal-body.aviso{
  display: flex;
  justify-content: center;
}

.modal-botones{
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.modal-body.aviso > .botones{
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
  width: 80%;
}

.modal-body.aviso > .confirmacion{
  padding: 1.5em;
}

.modal-body.aviso > .titulo{
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}

.div-error{
  color: red;
}


.icono-aviso{
  width: 30%;
}


/*===================== BARRA DE PROGRESO ==========================*/
.meter {
     box-sizing: content-box;
     height: 20px; /* Can be anything */
     position: relative;
     margin: 16px 0 16px 0; /* Just for demo spacing */
     background: #00dbde3d;
     border-radius: 25px;
     /*padding: 10px;*/
     box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter > span {
     display: block;
     height: 100%;
     border-top-right-radius: 8px;
     border-bottom-right-radius: 8px;
     border-top-left-radius: 20px;
     border-bottom-left-radius: 20px;
     background-color: rgb(43, 194, 83);
     background-image: linear-gradient(
          center bottom,
          rgb(43, 194, 83) 37%,
          rgb(84, 240, 84) 69%
     );
     box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
          inset 0 -2px 6px rgba(0, 0, 0, 0.4);
     position: relative;
     overflow: hidden;
     background-image: linear-gradient(#00dbde, #7f6def);
}

.meter > span:after,
.animate > span > span {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
     background-image: linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
     );
     z-index: 1;
     background-size: 50px 50px;
     animation: move 2s linear infinite;
     border-top-right-radius: 8px;
     border-bottom-right-radius: 8px;
     border-top-left-radius: 20px;
     border-bottom-left-radius: 20px;
     overflow: hidden;
}

.animate > span:after {
     display: none;
}

@keyframes move {
     0% {
          background-position: 0 0;
     }
     100% {
          background-position: 50px 50px;
     }
}




/*=====================TOOGLE======================================*/
.switch {
     position: relative;
     display: inline-block;
     width: 50px;
     height: 25px;
     margin-left: 5px;
}

.switch input {
     opacity: 0;
     width: 0;
     height: 0;
}

.slider {
     position: absolute;
     cursor: pointer;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #ccc;
     -webkit-transition: .4s;
     transition: .4s;
}

.slider:before {
     position: absolute;
     content: "";
     height: 20px;
     width: 20px;
     left: 4px;
     bottom: 2px;
     background-color: white;
     -webkit-transition: .4s;
     transition: .4s;
}

input:checked + .slider {
     background-color: #2196F3;
}

input:focus + .slider {
     box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
     -webkit-transform: translateX(20px);
     -ms-transform: translateX(20px);
     transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
     border-radius: 34px;
}

.slider.round:before {
     border-radius: 50%;
}



/*===============VERSION MOVIL======================*/
@media only screen and (max-width: 800px) {

     .titulo-componente, .cabecera-componente{
          margin-top: 0px;
          margin-bottom: 25px;
     }

     .tarjeta{
          display: flex;
          flex-flow: nowrap column;
          align-items: center;
     }
}
